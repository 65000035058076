import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import getYearlyDataCache, {
  IBaseState,
} from '../../sharedComponents/yearlyDataCache';
import {
  apiBaseURL,
  fetchJSON,
  IPYDatum,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

export type IState = IBaseState<IPYDatum>;

interface IHashInput {
  productClass: ProductClass;
}

interface IAPIResponse {
  data: IPYDatum[];
}

const getDataFromAPIResponse = ({data}: IAPIResponse) => data;

export const defaultHashFunction = ({productClass}: IHashInput) => productClass;

const FETCH_BEGIN = 'PRODUCT_YEAR_FETCH_BEGIN';
const FETCH_SUCCESS = 'PRODUCT_YEAR_FETCH_SUCCESS';
const FETCH_FAIL = 'PRODUCT_YEAR_FETCH_FAIL';
const FETCH_IF_NEEDED = 'PRODUCT_YEAR_FETCH_IF_NEEDED';

const {
  reducer,
  fetchDataEpic,
  fetchIfNeeded,
  getDataStatusSelector,
  getYearsStatusSelector,
} = getYearlyDataCache<
  IWorkerRootState,
  IPYDatum,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHashInput,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  outerHashFunction: defaultHashFunction,
  getCacheFromRootState: (rootState: IWorkerRootState) => rootState.productYear,
  getFetchPromise: ({productClass}: IHashInput) => {
    const productClassPhrase = (productClass === ProductClass.HS) ?
                                'hs_product' : 'sitc_product';
    return fetchJSON<IAPIResponse>(
      `${apiBaseURL}/data/${productClassPhrase}/?level=4digit`,
    );
  },
  getDataFromAPIResponse,
});

export default reducer;
export {fetchDataEpic, fetchIfNeeded, getDataStatusSelector, getYearsStatusSelector};
