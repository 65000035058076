import {
  ILoadable,
  LoadableStatus,
} from '../Utils';
import {
  GraphStatus,
  GraphStatusCode,
} from './Utils';

// TODO: use substraction type when it comes out so that we
// add the value for the key `loadableStatus` at the end of the function
// instead of adding it in every code path:
const getNewStatus = <Success, T>(
  // Value to assign when there's no data:
  noDataValue: T,
  prevGraphStatus: GraphStatus<Success, T>,
  nextLoadableStatus: ILoadable<Success>): GraphStatus<Success, T> => {

  // The value of the `loadableStatus` key that will be returned:
  const loadableStatus = nextLoadableStatus.status;

  let result: GraphStatus<Success, T>;
  if (prevGraphStatus.status === GraphStatusCode.Initial) {
    // Special hading for before the very first successful fetch/fail:
    if (nextLoadableStatus.status === LoadableStatus.Initial ||
        nextLoadableStatus.status === LoadableStatus.Loading) {

      result = {
        status: GraphStatusCode.Initial, loadableStatus,
      };
    } else if (nextLoadableStatus.status === LoadableStatus.Present) {
      result = {
        status: GraphStatusCode.Success,
        value: nextLoadableStatus.data,
        loadableStatus,
      };
    } else {
      result = {
        status: GraphStatusCode.Fail,
        value: noDataValue,
        loadableStatus,
      };
    }
  } else {
    // After the first successful fetch/fail:
    if (nextLoadableStatus.status === LoadableStatus.Initial ||
         nextLoadableStatus.status === LoadableStatus.Loading) {
      if (prevGraphStatus.status === GraphStatusCode.Success ||
          prevGraphStatus.status === GraphStatusCode.LoadingAfterSuccess) {
        result = {
          status: GraphStatusCode.LoadingAfterSuccess,
          value: prevGraphStatus.value,
          loadableStatus,
        };
      } else if (prevGraphStatus.status === GraphStatusCode.Fail ||
                  prevGraphStatus.status === GraphStatusCode.LoadingAfterFailure) {
        result = {
          status: GraphStatusCode.LoadingAfterFailure,
          value: prevGraphStatus.value,
          loadableStatus,
        };
      } else {
        throw new Error('Invalid combination: prev is ' + prevGraphStatus + ' next is' + nextLoadableStatus);
      }
    } else if (nextLoadableStatus.status === LoadableStatus.Present) {
      result = {
        status: GraphStatusCode.Success,
        value: nextLoadableStatus.data,
        loadableStatus,
      };
    } else {
      result = {
        status: GraphStatusCode.Fail,
        value: noDataValue,
        loadableStatus,
      };
    }
  }
  return result;
};

export default getNewStatus;
