import gql from 'graphql-tag';
import React, {useEffect, useState} from 'react';
import {
  Query,
  QueryResult,
} from 'react-apollo';
import ApolloProvider from '../contextProviders/ApolloProvider';
import {
  generateStringLocationId,
} from '../graphQL/dataFetchers/Utils';
import {
  Location,
} from '../graphQL/graphQLTypes';
import Tooltip from '../viz/Tooltip';
import { ChartTitle } from '../viz/VizGrid';

const query = gql`
  query titleInformation($locationId: ID!) {
    location(id: $locationId) {
      id
      shortName
    }
  }
`;

interface SuccessResponse {
  location: {
    id: Location['id'],
    shortName: Location['shortName'],
  } | null;
}

interface Variables {
  locationId: string;
}

type Result = QueryResult<SuccessResponse, Variables>;

interface Props {
  country: number | undefined;
  year: number;
  feasibility: boolean;
  setTitle?: (val: string) => void;
}

const ExploreGraphTitle = (props: Props) => {
  const {
    country, year, feasibility, setTitle,
  } = props;

  const [graphTitle, setGraphTitle] = useState<string>('');

  const updateGraphTitle = (val: string) => {
    if (val !== graphTitle) {
      setGraphTitle(val);
    }
  };

  useEffect(() => {
    if (setTitle !== undefined) {
      setTitle(graphTitle);
    }
  }, [graphTitle]);

  const locationId = country === undefined ? '' : generateStringLocationId(country);

  const variables: Variables = {locationId};

  const renderProp = (result: Result) => {
    const {loading, error, data} = result;
    if (loading === true) {
      return null;
    } else if (error !== undefined) {
      console.error(error);
      return null;
    } else if (data !== undefined) {
      const { location } = data;
      let title: string | null;
      if (feasibility === false) {
        title = location !== null
          ? `What did ${location.shortName} export in ${year}?`
          : null;
      } else {
        title = location !== null
          ? `Which products are feasible for ${location.shortName} in ${year}?`
          : null;
      }
      if (title) {
        updateGraphTitle(title);
        return (
          <Tooltip
            explanation={title}
            title={title}
          />
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <ChartTitle>
      <ApolloProvider>
        <Query
          query={query}
          children={renderProp}
          variables={variables}
        />
      </ApolloProvider>
    </ChartTitle>
  );
};

export default ExploreGraphTitle;
